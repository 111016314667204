import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openSnackbar } from "#redux/slices/globalUISlice";

export const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = title ? `${title} | Pagebound` : "Pagebound";
    return () => document.title = "Pagebound";
  }, [title]);
};


export const useShareLink = () => {
  const dispatch = useDispatch();

  return useCallback(async (path, title, text) => {
    const url = `${window.location.origin}${path}`;
    const shareData = { url, title, text };

    let success = false;
    navigator.share(shareData)
      .then(() => { success = true })
      .catch(() => {}) // ignore sharing errors
      .finally(() => {
        if (!success) {
          navigator.clipboard.writeText(`${title} ${url}`);
          dispatch(openSnackbar({ message: "Link copied to clipboard" }));
        }
      })
  }, [dispatch])
};
